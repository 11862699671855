import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { v4 as uuid } from 'uuid'
// import { delay } from 'bluebird'

import {
  AmigoHead,
  ConditionalRender,
  getRandomNumBetween,
  TAmigo,
  getAmigoName,
  IEvent,
  graphQLMutation,
  createCheer
} from '../internal'

export interface ICardProps {
    amigo: TAmigo,
    event?: IEvent,
    short?: boolean,
    cheerCount?: number,
    cheered?: boolean,
    screenClass?: string,
}

export const AmigoCard: FC<ICardProps> = ({ amigo, event, short, cheerCount, cheered, screenClass }) => {
  const [cheerClassName, setCheerClassName] = useState<string>(`amigo-card`);

  useEffect(() => {
    var desiredClass = classnames({
      'short' : !!short, 
      'cheer-blip': cheered && Number.isInteger(cheerCount) ? cheerCount! % 2 == 1 : false,
      'cheer-blip-2': cheered && Number.isInteger(cheerCount) ? cheerCount! % 2 == 0 : false,
    });
    
    setCheerClassName(desiredClass);
  }, [event?.name, cheerCount, cheered])

  const currName = getAmigoName(amigo, screenClass === 'xs');

  const cheerFunc = async () => {
    const now = Date.now();
    const cheerToSave = {
      id: uuid(),
      for: amigo,
      createdAt: now
    };
    await graphQLMutation(createCheer, cheerToSave);
    return;  
  }

  const getPlaceName = (placeIndex, shortName: boolean = false) => {
    switch(placeIndex) {
      case 0: 
        return shortName ? '1st' : '1st Place';
      case 1: 
        return shortName ? '2nd' : '2nd Place';
      case 2: 
        return shortName ? '3rd' : '3rd Place';
      default: 
        return '';
    }
  } 
  
  return  (
      <div className={classnames('amigo-card', `place-${event?.runningPositions[amigo]}`, screenClass, cheerClassName)}>
        
        <ConditionalRender visible={!short}>
          <AmigoHead
            noName
            amigo={amigo} />
      
          <div className="amigo-name">
            {currName}
          </div>
        </ConditionalRender>
        
        <ConditionalRender visible={!!short}>
          <Col sm={12}>
            <div className="card-place centered">
              {getPlaceName(event?.runningPositions[amigo], screenClass === 'xs')}
            </div>
          </Col>
          <Col sm={12}>
            <div className="card-points centered">
              {event?.totalEarned[amigo]} Points
            </div>
          </Col>
        </ConditionalRender>

        <ConditionalRender visible={!short}>
          <div className="bottom-anchor">
            <Col sm={12}>
              <div className="card-place centered">
                {getPlaceName(event?.runningPositions[amigo], screenClass === 'xs')}
              </div>
            </Col>
            <Col sm={12}>
              <div className="card-points centered">
                {event?.totalEarned[amigo]} Points
              </div>
            </Col>

              {/* Removed cheering & cheer counts -- was costing $20 a month! */}
              {/* <div className="cheer-container">
                <button onClick={() => cheerFunc?.()}>
                  Root for {currName}!
                </button>
              </div> */}
          </div>
        </ConditionalRender>
      </div>
  );
}