import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";

import {
  AmigoHead,
  EventStack,
  IEvent,
  ConditionalRender,
} from '../../internal';

const eventData = require('../../parsedEvents.json');

interface IProps {

}

export const Stacks: FC<IProps> = ({ }) => {
  var [latestEvent, setLatestEvent] = useState<IEvent>();
  var [eventList, setEventList] = useState<IEvent[]>();
  var [activeItemIndex, setActiveItemIndex] = useState<number>();


  useEffect(() => {
    const recent = eventData[eventData.length - 1];
    setLatestEvent(recent);

        
    var list = [...eventData].reverse();
    setEventList(list);

  }, []);

  return (
    <Container>
      <Row direction="column" justify="center">
        <Col xs={12}>
          <h2 className="centered">Ultimate Roommate Challenge</h2>
        </Col>

        <Row justify="around">
          <Col xs={4} style={{alignSelf: 'flex-end'}}> 
            <EventStack amigo="m" events={eventList} activeIndex={activeItemIndex} setActiveIndex={setActiveItemIndex} />
          </Col>

            <Col xs={4} style={{alignSelf: 'flex-end'}}>
              <EventStack amigo="s" events={eventList} activeIndex={activeItemIndex} setActiveIndex={setActiveItemIndex} />
            </Col>

            <Col xs={4} style={{alignSelf: 'flex-end'}}>
              <EventStack amigo="a" events={eventList} activeIndex={activeItemIndex} setActiveIndex={setActiveItemIndex} />
            </Col>

        </Row>
          <ConditionalRender visible={eventList?.length! > 0}>
          <Col xs={12}>
            <h3 className="centered">"Black And"</h3>
            <h3 className="centered">"Gold, Lets"</h3>
            <h3 className="centered">"Gooo Knights!"</h3>
          </Col>
        </ConditionalRender>
      </Row >      
    </Container>
  )
}
