import React, { FC, useState, useEffect, useContext, ReactElement } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import {
  FaBasketballBall,
  FaDice,
  FaRunning,
} from 'react-icons/fa';
import {
  GiSheep,
  GiDiscGolfBasket,
  GiTennisRacket,
  GiPawn,
  GiPokerHand,
  GiSoccerBall,
  GiBowlingPin,
  GiPoolTriangle
}
from 'react-icons/gi';
import {
  MdOutlineGolfCourse,
  MdVideogameAsset,
  MdOutlineRemoveRedEye
} from 'react-icons/md';

import {
  AmigoHead,
  EventStack,
  IEvent,
} from '../../internal';
import { ConditionalRender } from 'components';

const eventData = require('../../parsedEvents.json');

interface IProps {

}

export const Events: FC<IProps> = ({ }) => {
  var [latestEvent, setLatestEvent] = useState<IEvent>();
  var [eventList, setEventList] = useState<IEvent[]>(eventData);
  var skeletonEvent = {
    name: '',
    date: '',
    eventEarned: {a: 0, m: 0, s: 0},
    eventPositions: {a: 0, m: 0, s: 0},
    runningPositions: {a: 0, m: 0, s: 0},
    totalEarned: {a: 0, m: 0, s: 0},
    totalFirstPlaces: {a: 0, m: 0, s: 0},
    totalSecondPlaces: {a: 0, m: 0, s: 0},
    totalThirdPlaces: {a: 0, m: 0, s: 0},
    totalPointsLost: {a: 0, m: 0, s: 0},
    largestSingleEventPointsEarned: 0,
    largestSingleEventPointsAmigo: '',
    largestSingleEventPointsName: '',
    largestPointLead: {a: 0, m: 0, s: 0},
    longestFirstPlaceStreak: {a: 0, m: 0, s: 0},
    currentFirstPlaceStreak: {a: 0, m: 0, s: 0},
  };
  var [skeletonEventList, setSkeletonEventList] = useState<IEvent[]>([skeletonEvent, skeletonEvent, skeletonEvent, skeletonEvent, skeletonEvent, skeletonEvent]);

  useEffect(() => {
    const recent = eventData[eventData.length - 1];
    setLatestEvent(recent);

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000)
    
    setTimeout(() => {
      var list = [...eventData].reverse();
      setEventList(list);
    }, 10);

  }, []);

  const displayEventIcons = (ev: IEvent) => {
    var display: ReactElement[] = [];
    // amigos flags
    if (ev.isPP) {
      display.push(<div className="category-icon pp" data-tip="Pitty Point"><i>PP</i></div>);
    }
    if (ev.isMM) {
      display.push(<div className="category-icon mm" data-tip="Milestone Moment"><i>MM</i></div>);
    }
    if (ev.isBLC) {
      display.push(<div className="category-icon blc" data-tip="Big Life Change"><i>BLC</i></div>);
    }


    if (ev.isBball) {
      display.push(<div className="category-icon" data-tip="Basketball"><FaBasketballBall /></div>);
    }
    if (ev.isCatan) {
      display.push(<div className="category-icon" data-tip="Catan"><GiSheep /></div>);
    }
    if (ev.isDiscGolf) {
      display.push(<div className="category-icon" data-tip="Disc Golf"><GiDiscGolfBasket /></div>);
    }
    if (ev.isTennis) {
      display.push(<div className="category-icon" data-tip="Tennis"><GiTennisRacket /></div>);
    }
    if (ev.isRomazarc) {
      display.push(<div className="category-icon" data-tip="Romazarc"><GiPawn /></div>);
    }
    if (ev.isSoccer) {
      display.push(<div className="category-icon" data-tip="Soccer"><GiSoccerBall /></div>);
    }
    if (ev.isGolf) {
      display.push(<div className="category-icon" data-tip="Golf"><MdOutlineGolfCourse /></div>);
    }
    if (ev.isBowling) {
      display.push(<div className="category-icon" data-tip="Bowling"><GiBowlingPin /></div>);
    }
    if (ev.isBilliards) {
      display.push(<div className="category-icon" data-tip="Billiards"><GiPoolTriangle /></div>);
    }
    if (ev.isFinder) {
      display.push(<div className="category-icon" data-tip="Good Eye"><MdOutlineRemoveRedEye /></div>);
    }
    if (ev.isPoker) {
      display.push(<div className="category-icon" data-tip="Poker"><GiPokerHand /></div>);
    }

    // larger categories
    if (ev.isBoardGame) {
      display.push(<div className="category-icon" data-tip="Board Game"><FaDice /></div>);
    }
    if (ev.isSports) {
      display.push(<div className="category-icon" data-tip="Sports"><FaRunning /></div>);
    }
    if (ev.isVideoGame) {
      display.push(<div className="category-icon" data-tip="Video Game"><MdVideogameAsset /></div>);
    }
    
    
    
    return display;
  }

  const displayEventRow = (ev: IEvent, evIndex: number) => {
    return (
      <Row className="event-row" key={`event-${evIndex}`}>
        <Col md={7} lg={8} className={classnames({ 'pp': ev.isPP, 'mm': ev.isMM, 'blc': ev.isBLC })}>
          <Row>
            <Col>
              <h6 className="event-date">
                {ev.date}
              </h6>
            </Col>
            <Col>
              <div className="event-icons-container">
                {displayEventIcons(ev)}
              </div>
            </Col>
            <Col xs={12}>
              <h4 className="event-name">
                {ev.name}
              </h4>
            </Col>
          </Row>
          {/* <div className={classnames('event-flag-container', { 'pp': ev.isPP, 'mm': ev.isMM, 'blc': ev.isBLC })}></div> */}
        </Col>
        <Col md={5} lg={4}>
          <Row style={{ height: '100%', alignItems: 'center' }}>
          <Col xs={4} >
            <AmigoHead
              amigo="m"
              noName
              noPic={false}
              noRotate
              textBelow={`${ev.eventEarned['m']}`} 
              standingsForBorder={ev.eventPositions} />
          </Col>
          <Col xs={4}>
            <AmigoHead
              amigo="s"
              noName
              noPic={false}
              noRotate
              textBelow={`${ev.eventEarned['s']}`} 
              standingsForBorder={ev.eventPositions} />
          </Col>
          <Col xs={4}>
            <AmigoHead
              amigo="a"
              noName
              noPic={false}
              noRotate
              textBelow={`${ev.eventEarned['a']}`} 
              standingsForBorder={ev.eventPositions} />
          </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return (
    <Container>
      <div className="events-page">
        <Row direction="column" justify="center">
          <Col xs={12}>
            <h2 className="centered">Ultimate Roommate Challenge Events</h2>
          </Col>
          <ConditionalRender visible={!eventList?.[0]?.name.startsWith('1.')}>
            {eventList.map(displayEventRow)}
          </ConditionalRender>

          {/* skeleton loader */}
          <ConditionalRender visible={eventList?.[0]?.name.startsWith('1.')}>
            {skeletonEventList.map(displayEventRow)}
          </ConditionalRender>
        </Row>
      </div>
    </Container>
  )
}
