import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { ScreenClassRender, Container, Row, Col, Hidden } from 'react-grid-system';


import {
  keepTheme,
  Projects,
  Landing,
  Header,
  Events,
  Stacks,
  Statistics,
  ConditionalRender
} from './internal'


// Init
const App = () => {

  useEffect(() => {
    keepTheme();
  }, [])

  return (
    <React.StrictMode>
      <ScreenClassRender render={(screenClass: string) => (
        <>
          <Router>
            <Header />
            <Switch>

              <Route exact path='/standings'>
                <Landing />
              </Route>

              <Route exact path='/events'>
                <Events />
              </Route>

              <Route exact path='/stacks'>
                <Stacks />
              </Route>

              <Route exact path='/stats'>
                <Statistics />
              </Route>

              <Route exact path='/pages/projects'>
                <Projects />
              </Route>

              <Route
                exact
                path="/"
                render={() => <Redirect to="/standings" />}
              />
              
            </Switch>
          </Router>
        </>
      )} />
      <ReactTooltip effect="solid" multiline={true} />
      {/* <Footer /> */}
    </React.StrictMode >
  )
}

export default App
