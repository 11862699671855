import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames'

import {
  ConditionalRender,
  getRandomNumBetween,
  TAmigo,
  getAmigoName,
  IEvent,
  IAmigoVals
} from '../internal'


import alexHead1a from '../assets/imgs/heads/alex1a.png'
import alexHead1b from '../assets/imgs/heads/alex1b.png'
import alexHead1c from '../assets/imgs/heads/alex1c.png'
import alexHead2a from '../assets/imgs/heads/alex2a.png'
import alexHead3a from '../assets/imgs/heads/alex3a.png'
import alexHead3b from '../assets/imgs/heads/alex3b.png'
import alexHead4a from '../assets/imgs/heads/alex4a.png'
import alexHead5a from '../assets/imgs/heads/alex5a.png'
import alexHead6a from '../assets/imgs/heads/alex6a.png'
import alexHead6b from '../assets/imgs/heads/alex6b.png'
import alexHead7a from '../assets/imgs/heads/alex7a.png'
import alexHead8a from '../assets/imgs/heads/alex8a.png'
import alexHead9a from '../assets/imgs/heads/alex9a.png'
import alexHead10a from '../assets/imgs/heads/alex10a.png'
import alexHead11a from '../assets/imgs/heads/alex11a.png'
import alexHead12a from '../assets/imgs/heads/alex12a.png'
import alexHead13a from '../assets/imgs/heads/alex13a.png'
import alexHead14a from '../assets/imgs/heads/alex14a.png'
import alexHead14b from '../assets/imgs/heads/alex14b.png'
import alexHead14c from '../assets/imgs/heads/alex14c.png'
import alexHead15a from '../assets/imgs/heads/alex15a.png'
import alexHead16a from '../assets/imgs/heads/alex16a.png'
import alexHead16b from '../assets/imgs/heads/alex16b.png'
import alexHead16c from '../assets/imgs/heads/alex16c.png'
import alexHead17a from '../assets/imgs/heads/alex17a.png'
import alexHead17b from '../assets/imgs/heads/alex17b.png'
import alexHead18a from '../assets/imgs/heads/alex18a.png'
import alexHead19a from '../assets/imgs/heads/alex19a.png'
import alexHead20a from '../assets/imgs/heads/alex20a.png'
import alexHead20b from '../assets/imgs/heads/alex20b.png'
import alexHead21a from '../assets/imgs/heads/alex21a.png'
import alexHead21b from '../assets/imgs/heads/alex21b.png'
import alexHead23a from '../assets/imgs/heads/alex23a.png'
import alexHead23b from '../assets/imgs/heads/alex23b.png'
import alexHead24a from '../assets/imgs/heads/alex24a.png'
import alexHead25a from '../assets/imgs/heads/alex25a.png'
import alexHead25b from '../assets/imgs/heads/alex25b.png'
import alexHead25c from '../assets/imgs/heads/alex25c.png'
import alexHead26a from '../assets/imgs/heads/alex26a.png'
import alexHead26b from '../assets/imgs/heads/alex26b.png'
import alexHead26c from '../assets/imgs/heads/alex26c.png'
import alexHead26d from '../assets/imgs/heads/alex26d.png'
import alexHead27a from '../assets/imgs/heads/alex27a.png'
import alexHead27b from '../assets/imgs/heads/alex27b.png'

import scottHead1 from '../assets/imgs/heads/scott1.png'
import scottHead2a from '../assets/imgs/heads/scott2a.png'
import scottHead2b from '../assets/imgs/heads/scott2b.png'
import scottHead2c from '../assets/imgs/heads/scott2c.png'
import scottHead3a from '../assets/imgs/heads/scott3a.png'
import scottHead3b from '../assets/imgs/heads/scott3b.png'
import scottHead3c from '../assets/imgs/heads/scott3c.png'
import scottHead3d from '../assets/imgs/heads/scott3d.png'
import scottHead4a from '../assets/imgs/heads/scott4a.png'
import scottHead4b from '../assets/imgs/heads/scott4b.png'
import scottHead5a from '../assets/imgs/heads/scott5a.png'
import scottHead5b from '../assets/imgs/heads/scott5b.png'
import scottHead6a from '../assets/imgs/heads/scott6a.png'
import scottHead7a from '../assets/imgs/heads/scott7a.png'
import scottHead8a from '../assets/imgs/heads/scott8a.png'
import scottHead9a from '../assets/imgs/heads/scott9a.png'
import scottHead10a from '../assets/imgs/heads/scott10a.png'
import scottHead10b from '../assets/imgs/heads/scott10b.png'
import scottHead11a from '../assets/imgs/heads/scott11a.png'
import scottHead12a from '../assets/imgs/heads/scott12a.png'
import scottHead13a from '../assets/imgs/heads/scott13a.png'
import scottHead14a from '../assets/imgs/heads/scott14a.png'
import scottHead15a from '../assets/imgs/heads/scott15a.png'
import scottHead16a from '../assets/imgs/heads/scott16a.png'
import scottHead16b from '../assets/imgs/heads/scott16b.png'
import scottHead17a from '../assets/imgs/heads/scott17a.png'
import scottHead17b from '../assets/imgs/heads/scott17b.png'
import scottHead18a from '../assets/imgs/heads/scott18a.png'
import scottHead19b from '../assets/imgs/heads/scott19b.png'
import scottHead20a from '../assets/imgs/heads/scott20a.png'
import scottHead21a from '../assets/imgs/heads/scott21a.png'
import scottHead22a from '../assets/imgs/heads/scott22a.png'
import scottHead23a from '../assets/imgs/heads/scott23a.png'
import scottHead24a from '../assets/imgs/heads/scott24a.png'
import scottHead24b from '../assets/imgs/heads/scott24b.png'
import scottHead24c from '../assets/imgs/heads/scott24c.png'
import scottHead25a from '../assets/imgs/heads/scott25a.png'
import scottHead26a from '../assets/imgs/heads/scott26a.png'
import scottHead27a from '../assets/imgs/heads/scott27a.png'
import scottHead27b from '../assets/imgs/heads/scott27b.png'
import scottHead28a from '../assets/imgs/heads/scott28a.png'
import scottHead28b from '../assets/imgs/heads/scott28b.png'
import scottHead29a from '../assets/imgs/heads/scott29a.png'
import scottHead30a from '../assets/imgs/heads/scott30a.png'
import scottHead30b from '../assets/imgs/heads/scott30b.png'
import scottHead30c from '../assets/imgs/heads/scott30c.png'
import scottHead31a from '../assets/imgs/heads/scott31a.png'
import scottHead31b from '../assets/imgs/heads/scott31b.png'
import scottHead31c from '../assets/imgs/heads/scott31c.png'



import mjHead1 from '../assets/imgs/heads/mj1.png'
import mjHead2a from '../assets/imgs/heads/mj2a.png'
import mjHead3a from '../assets/imgs/heads/mj3a.png'
import mjHead3b from '../assets/imgs/heads/mj3b.png'
import mjHead3c from '../assets/imgs/heads/mj3c.png'
import mjHead4a from '../assets/imgs/heads/mj4a.png'
import mjHead4b from '../assets/imgs/heads/mj4b.png'
import mjHead4c from '../assets/imgs/heads/mj4c.png'
import mjHead5a from '../assets/imgs/heads/mj5a.png'
import mjHead6a from '../assets/imgs/heads/mj6a.png'
import mjHead7a from '../assets/imgs/heads/mj7a.png'
import mjHead8a from '../assets/imgs/heads/mj8a.png'
import mjHead9a from '../assets/imgs/heads/mj9a.png'
import mjHead9b from '../assets/imgs/heads/mj9b.png'
import mjHead10a from '../assets/imgs/heads/mj10a.png'
import mjHead10b from '../assets/imgs/heads/mj10b.png'
import mjHead11a from '../assets/imgs/heads/mj11a.png'
import mjHead12a from '../assets/imgs/heads/mj12a.png'
import mjHead12b from '../assets/imgs/heads/mj12b.png'
import mjHead13a from '../assets/imgs/heads/mj13a.png'
import mjHead13b from '../assets/imgs/heads/mj13b.png'
import mjHead14a from '../assets/imgs/heads/mj14a.png'
import mjHead15b from '../assets/imgs/heads/mj15b.png'
import mjHead15c from '../assets/imgs/heads/mj15c.png'
import mjHead16a from '../assets/imgs/heads/mj16a.png'
import mjHead17a from '../assets/imgs/heads/mj17a.png'
import mjHead18a from '../assets/imgs/heads/mj18a.png'
import mjHead19a from '../assets/imgs/heads/mj19a.png'
import mjHead19b from '../assets/imgs/heads/mj19b.png'
import mjHead20a from '../assets/imgs/heads/mj20a.png'
import mjHead21a from '../assets/imgs/heads/mj21a.png'
import mjHead22a from '../assets/imgs/heads/mj22a.png'
import mjHead23a from '../assets/imgs/heads/mj23a.png'
import mjHead23b from '../assets/imgs/heads/mj23b.png'
import mjHead23c from '../assets/imgs/heads/mj23c.png'
import mjHead23d from '../assets/imgs/heads/mj23d.png'
import mjHead25a from '../assets/imgs/heads/mj25a.png'
import mjHead26a from '../assets/imgs/heads/mj26a.png'
import mjHead26b from '../assets/imgs/heads/mj26b.png'
import mjHead27a from '../assets/imgs/heads/mj27a.png'
import mjHead27b from '../assets/imgs/heads/mj27b.png'
import mjHead28a from '../assets/imgs/heads/mj28a.png'
import mjHead29a from '../assets/imgs/heads/mj29a.png'
import mjHead29b from '../assets/imgs/heads/mj29b.png'
import mjHead30a from '../assets/imgs/heads/mj30a.png'
import mjHead31a from '../assets/imgs/heads/mj31a.png'
import mjHead31b from '../assets/imgs/heads/mj31b.png'
import mjHead31c from '../assets/imgs/heads/mj31c.png'
import mjHead32a from '../assets/imgs/heads/mj32a.png'
import mjHead33a from '../assets/imgs/heads/mj33a.png'
import mjHead33b from '../assets/imgs/heads/mj33b.png'
import mjHead33c from '../assets/imgs/heads/mj33c.png'


const headImgs = {
  a: [
    [ alexHead1a, alexHead1b, alexHead1c ],
    [ alexHead2a ],
    [ alexHead3a, alexHead3b ],
    [ alexHead4a ],
    [ alexHead5a ],
    [ alexHead6a, alexHead6b ],
    [ alexHead7a ],
    [ alexHead8a ],
    [ alexHead9a ],
    [ alexHead10a ],
    [ alexHead12a ],
    [ alexHead13a ],
    [ alexHead14a, alexHead14b, alexHead14c ],
    [ alexHead15a ],
    [ alexHead16a, alexHead16b, alexHead16c ],
    [ alexHead17a, alexHead17b ],
    [ alexHead18a ],
    [ alexHead19a ],
    [ alexHead20a, alexHead20b ],
    [ alexHead21a, alexHead21b ], 
    [ alexHead23a, alexHead23b ],
    [ alexHead24a ],
    [ alexHead25a, alexHead25b, alexHead25c ],
    [ alexHead26a, alexHead26b,  alexHead26c, alexHead26d],
    [ alexHead27a, alexHead27b ],
  ],
  m: [
    [ mjHead1 ],
    [ mjHead2a ],
    [ mjHead3a, mjHead3b, mjHead3c ],
    [ mjHead4a, mjHead4b, mjHead4c ],
    [ mjHead5a ],
    [ mjHead6a ],
    [ mjHead7a ],
    [ mjHead8a ],
    [ mjHead9a, mjHead9b ],
    [ mjHead10a, mjHead10b ],
    [ mjHead11a ],
    [ mjHead12a, mjHead12b ],
    [ mjHead13a, mjHead13b ],
    [ mjHead14a ],
    [ mjHead15b, mjHead15c ],
    [ mjHead16a ],
    [ mjHead17a ],
    [ mjHead18a ],
    [ mjHead19a, mjHead19b ],
    [ mjHead20a ],
    [ mjHead21a ],
    [ mjHead22a ],
    [ mjHead23a, mjHead23b, mjHead23c, mjHead23d ],
    [ mjHead25a ],
    [ mjHead26a, mjHead26b ],
    [ mjHead27a, mjHead27b ],
    [ mjHead28a ],
    [ mjHead29a, mjHead29b ],
    [ mjHead30a ],
    [ mjHead31a, mjHead31b, mjHead31c ],
    [ mjHead32a ],
    [ mjHead33a, mjHead33b, mjHead33c ],
  ],
  s: [
    [ scottHead1 ],
    [ scottHead2a, scottHead2b, scottHead2c ],
    [ scottHead3a,  scottHead3b ], 
    [ scottHead3c, scottHead3d ], //separate on purpose
    [ scottHead4a, scottHead4b ],
    [ scottHead5a, scottHead5b ],
    [ scottHead6a ],
    [ scottHead7a ],
    [ scottHead8a ],
    [ scottHead9a ],
    [ scottHead10a, scottHead10b ],
    [ scottHead11a ],
    [ scottHead12a ],
    [ scottHead13a ],
    [ scottHead14a ],
    [ scottHead15a ],
    [ scottHead16a, scottHead16b ],
    [ scottHead17a, scottHead17b ],
    [ scottHead18a ],
    [ scottHead19b ],
    [ scottHead20a ],
    [ scottHead21a ],
    [ scottHead22a ],
    [ scottHead23a ], 
    [ scottHead24a, scottHead24b, scottHead24c ],
    [ scottHead25a ],
    [ scottHead26a ],
    [ scottHead27a, scottHead27b ],
    [ scottHead28a, scottHead28b,  ],
    [ scottHead29a ],
    [ scottHead30a, scottHead30b, scottHead30c  ],
    [ scottHead31a, scottHead31b, scottHead31c  ]
  ]
}

export interface IFaceProps {
    amigo: TAmigo,
    noName?: boolean,
    noPic?: boolean,
    noRotate?: boolean,
    standingsForBorder?: IAmigoVals,
    standingsForBelow?: IAmigoVals,
    textBelow?: string
}

export const AmigoHead: FC<IFaceProps> = ({ amigo, noName, noPic, noRotate, standingsForBorder, standingsForBelow, textBelow }) => {

  const currName = getAmigoName(amigo);

  const amigoImgs = headImgs[amigo];
  const imgSetIndex = getRandomNumBetween(0, amigoImgs.length);
  const imgSet = amigoImgs[imgSetIndex];
  
  const [displayImg, setDisplayImg] = useState(imgSet[0]);
  
  useEffect(() => {
    var rotatorTimeout;
    if (!noPic) {

      if (imgSet.length > 0) {
        rotatorTimeout = rotateImg();
      }
    }
      
    return () => {
      clearTimeout(rotatorTimeout);
    }
  }, []);

  const rotateImg = () => {
    if (!noRotate) {
      return setTimeout(() => {
        const imgInSet = imgSet[getRandomNumBetween(0, imgSet.length)];
        setDisplayImg(imgInSet);
        rotateImg();
      }, getRandomNumBetween(500, 700))
    }
  }

  const cheerFunc = () => {
    console.log(`${currName} - img 0-based index: ${imgSetIndex}`);
    alert(`Horray for ${currName}!`)
  }

  return  (
    <div className={classnames('amigo-head', { 'w-below': !!textBelow })}>
      <button onClick={() => cheerFunc?.()}>

        <ConditionalRender visible={!noPic}>
          <img
            className={classnames(!!standingsForBorder ? `border-pos-${standingsForBorder[amigo]}` : '')}
            src={displayImg}
            alt={`image of ${currName}'`} />
        </ConditionalRender>

        <ConditionalRender visible={!!noPic}>
          <div className={classnames('no-pic-circle', amigo)}>
            {amigo}
          </div>
        </ConditionalRender>

        <ConditionalRender visible={!noName}>
          <div className="amigo-name">
            {currName}
          </div>
        </ConditionalRender>

        <ConditionalRender visible={!!textBelow}>
          <div className={classnames('text-below', !!standingsForBelow ? `pos-${standingsForBelow[amigo]}` : '')}>
            {textBelow}
          </div>
        </ConditionalRender>

      </button>
    </div>
  );
}