import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import {
  ConditionalRender,
  IEvent,
  AmigoHead,
  Trophy
  
} from '../internal';

export type TAmigo  = 'a'  | 'm' | 's' ; 

interface IProps {
  amigo: TAmigo,
  events?: IEvent[],
  activeIndex?: number,
  setActiveIndex?: (ind: number) => void
}

export const EventStack: FC<IProps> = ({ amigo, events, activeIndex, setActiveIndex }) => {
  var [latestEvent, setLatestEvent] = useState<IEvent>();

  useEffect(() => {
    const recent = events?.[0];
    setLatestEvent(recent);
      ReactTooltip.rebuild();
  }, [events]);

  const calcDisplayAndPoints = () => {
    return events?.reduce((all: any, currEv: IEvent, evIndex: number) => {

      const earnedPoints = currEv.eventEarned[amigo];
      all.totalPoints += earnedPoints;
      // console.log(`processing ${currEv.name} with ${earnedPoints} points`);

      const klass = classnames('event-block', 'centered', {
        'earned': earnedPoints > 0,
        'pp': currEv.isPP,
        'mm': currEv.isMM,
        'blc': currEv.isBLC,
        'force-hover': activeIndex === evIndex,
        'in-first': currEv.runningPositions[amigo] === 0,
        'in-second': currEv.runningPositions[amigo] === 1,
        'in-third': currEv.runningPositions[amigo] === 2
        
      });
      
      all.elems.push(
        <Col 
          key={`${amigo}-${evIndex}`}
          xs={12}>
          <div className={klass}
            style={{ height: `${(earnedPoints+0)*20}px`, textAlign: 'center' }}
            data-tip={`
              ${currEv.name.toUpperCase()}<br/>
              ${currEv.date}<br/>
              ${currEv.isMM ? 'Milestone Moment<br/>' : ''}
              ${currEv.isBLC ? 'Big Life Change<br/>' : ''}
              ${currEv.isPP ? 'Pitty Point<br/>' : ''}
              <br/>
              Michael: ${currEv?.eventEarned?.m} | Alex: ${currEv?.eventEarned?.a} | Scott: ${currEv?.eventEarned?.s}<br/>
            `}
            onMouseOver={setActiveIndex?.bind(null, evIndex)}>
            <div className="event-points">
              <ConditionalRender visible={earnedPoints != 0}>

                <h4 className="curr-points">{earnedPoints}</h4>
                <h4 className="running-total">({currEv.totalEarned[amigo]})</h4>
              </ConditionalRender>
            </div>

            {/* Milestone Moment */}
            <ConditionalRender visible={earnedPoints != 0 && (!!currEv.isMM)}>
              <div className="event-flag-container">
              <h5>MM</h5>
              </div>
            </ConditionalRender>

            {/* Big Life Change */}
            <ConditionalRender visible={earnedPoints != 0 && !!currEv.isBLC}>
              <div className="event-flag-container">
              <h5>BLC</h5>
              </div>
            </ConditionalRender>

            {/* Pitty Point */}
            <ConditionalRender visible={earnedPoints != 0 && (!!currEv.isPP)}>
              <div className="event-flag-container">
                <h5>PP</h5>
              </div>
            </ConditionalRender>
          </div>
        </Col>
      );
      return all;
    }, { elems: [], totalPoints: 0 });
  };

  var prepedData = calcDisplayAndPoints();

  return (
    <div className="event-stack">
      <Row justify="center">
        <Col>
          <AmigoHead
            amigo={amigo}
            standingsForBorder={latestEvent?.runningPositions} /> 

          <Trophy 
            amigo={amigo}
            standings={latestEvent?.runningPositions} />
        </Col>
      </Row>

      <Row> 
        <h3 className="stack-total">{prepedData?.totalPoints}</h3>
      </Row>
      
      <Row>
        {prepedData?.elems}
      </Row>
    </div>
  )
}
