export const getCheers = /* GraphQL */ `
  query GetCheers($for: String) {
    getCheer(filter: { for: $for }) {
      items {
        id
        for
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const countCheers = /* GraphQL */ `
  query CountCheers($for: String) {
    countCheer(filter: { for: { eq: $for }}) {
      total # Specify to get total counts
    }
  }
`;