import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import c3 from 'c3';
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";

import {
  ConditionalRender,
  AmigoHead,
  StatBlock,
  TAmigo,
  IEvent,
  Chart,
  getAmigoName,
  getAmigoAbbreviation
} from '../../internal';

const eventData = require('../../parsedEvents.json');

interface IProps {

}

export const Statistics: FC<IProps> = ({ }) => {
  var [runningTotals, setRunningTotals]  = useState<any>({ m: [], a: [], s: [] });
  var [latestEvent, setLatestEvent] = useState<IEvent>();
  var [firstEvent, setFirstEvent] = useState<IEvent>();
  var [selectedChartType, setSelectedChartType] = useState<string>('eventSpaced');

  useEffect(() => {
    setLatestEvent(eventData?.[eventData?.length - 1]);
    setFirstEvent(eventData?.[0]);

    const calcedTotals = eventData?.reduce((breakdown: any, currEv: IEvent, currIndex: number) => {
      breakdown.date.push(new Date(currEv?.date ?? '2023-04-06')?.toLocaleDateString?.()?.replace?.(/\//g, '-'));
      breakdown.m.push(currEv.eventEarned.m + (currIndex > 0 ? breakdown.m?.[currIndex-1] : 0));
      breakdown.a.push(currEv.eventEarned.a + (currIndex > 0 ? breakdown.a?.[currIndex-1] : 0));
      breakdown.s.push(currEv.eventEarned.s + (currIndex > 0 ? breakdown.s?.[currIndex-1] : 0));
      return breakdown;
    }, {date: [], m: [], a: [], s: []});

    setRunningTotals(calcedTotals);
  }, []);

  const keyWithHighestVal = (obj) => {
    if (!!obj) {
      return Object.keys(obj)?.reduce((a, b) => obj[a] > obj[b] ? a : b);
    }
    else { 
      return '';
    }
  }

  const highestVal = (obj) => {
    var key = keyWithHighestVal(obj);
    return obj?.[key];
  };

  const toggleChartType = () => {
    setSelectedChartType(selectedChartType === 'dateSpaced' ? 'eventSpaced' : 'dateSpaced');
  }

  const eventDateDiff = (startDate, endDate, opts?: any) => {
    const timeDiff = endDate.getTime() - startDate.getTime();
    const dayDiff = timeDiff / (1000 * 3600 * 24);

    if (opts?.daysOnly) { 
      return `${Math.floor(dayDiff)} days`;
    }

    const years = Math.floor(dayDiff / 365);
    const dayRemainder = Math.floor(dayDiff % 365);
    return `${years} years ${dayRemainder} days`;
  }

  return (
    <>
      <Row direction="column" justify="center" nogutter>
        <Col xs={12}>
          <h2 className="centered">Ultimate Roommate Challenge Statistics</h2>
        </Col>

        <ConditionalRender visible={runningTotals?.m?.length > 0}>
          <ConditionalRender visible={selectedChartType === 'eventSpaced'}>
            <Col lg={10} push={{ lg: 1 }}> 
              <Chart
                chartId="stats1"
                key={runningTotals?.m?.length}
                data={{
                  columns: [
                    ['m', ...runningTotals?.m],
                    ['a', ...runningTotals?.a],
                    ['s', ...runningTotals?.s],
                  ],
                  type: 'area',
                }}
                tooltip={{
                  format: {
                      title: function (d, index) { return eventData?.[index]?.name; },
                      name: (id) => getAmigoName(id),
                      value: function (value, ratio, id, index) {
                          return `${eventData?.[index]?.eventEarned?.[id]} (TOTAL:${value})`;
                      }
                  }
              }}
              axis={{
                x:{
                  type: 'linear',
                  tick: {
                    format: function (index) { return eventData?.[index]?.date ?? '01-01-1992' }
                  }
                }
              }} />
            </Col>
          </ConditionalRender>

          {/* <ConditionalRender visible={selectedChartType === 'dateSpaced'}>
            <Col lg={10} push={{ lg: 1 }}> 
              <Chart
                chartId="timeseries1"
                key={runningTotals?.m?.length}
                data={{
                  x: 'x',
                  xFormat: '%m-%d-%Y',
                  columns: [
                    ['x', ...runningTotals?.date],
                    ['m', ...runningTotals?.m],
                    ['a', ...runningTotals?.a],
                    ['s', ...runningTotals?.s],
                  ],
                  type: 'line',
                }}
                tooltip={{
                  format: {
                      title: function (d, index) { return eventData?.[index]?.name; },
                      name: (id) => getAmigoName(id),
                      value: function (value, ratio, id, index) {
                          return `${eventData?.[index]?.eventEarned?.[id]} (TOTAL:${value})`;
                      }
                  }
              }}
              axis={{
                x:{
                  type: 'timeseries',
                  tick: {
                    format: '%m-%d-%Y'
                  }
                }
              }} />
            </Col>
          </ConditionalRender> */}

          {/* <Col lg={5} md={4} xs={2} push={{ lg: 2, md: 4, xs: 5 }}> 
            <button className="chart-toggle" onClick={() => toggleChartType?.()} >
              View {selectedChartType === 'dateSpaced' ? 'Event' : 'Date'} Based Chart
            </button>
          </Col> */}
        </ConditionalRender>
      </Row>
      
      <Row justify="center" gutterWidth={6}>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most 1st Place Events" 
            metric={latestEvent?.totalFirstPlaces}
            value={`${highestVal(latestEvent?.totalFirstPlaces)} events`}
            amigo={keyWithHighestVal(latestEvent?.totalFirstPlaces) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most 2nd Place Events"
            metric={latestEvent?.totalSecondPlaces}
            value={`${highestVal(latestEvent?.totalSecondPlaces)} events`}
            amigo={keyWithHighestVal(latestEvent?.totalSecondPlaces) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most 3rd Place Events"
            value={`${highestVal(latestEvent?.totalThirdPlaces)} events`}
            amigo={keyWithHighestVal(latestEvent?.totalThirdPlaces) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Ultimate Roommate Challenge Duration"
            value={`${eventDateDiff(new Date(firstEvent?.date ?? ''), new Date(latestEvent?.date ?? ''))}`} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Days since last event"
            value={`${eventDateDiff(new Date(latestEvent?.date ?? ''), new Date(), { daysOnly: true })}`} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Milestone Moment Events"
            metric={latestEvent?.totalMMPointEvents}
            value={`${highestVal(latestEvent?.totalMMPointEvents)} events`}
            amigo={keyWithHighestVal(latestEvent?.totalMMPointEvents) as TAmigo} />
        </Col>
            
        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Milestone Moment Points"
            metric={latestEvent?.totalMMPoints}
            value={`${highestVal(latestEvent?.totalMMPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalMMPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most BLC Events"
            metric={latestEvent?.totalBLCPointEvents}
            value={`${highestVal(latestEvent?.totalBLCPointEvents)} events`}
            amigo={keyWithHighestVal(latestEvent?.totalBLCPointEvents) as TAmigo} />
        </Col>
            
        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most BLC Points"
            metric={latestEvent?.totalBLCPoints}
            value={`${highestVal(latestEvent?.totalBLCPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalBLCPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Pitty Points Handed"
            metric={latestEvent?.totalPittyPoints}
            value={`${highestVal(latestEvent?.totalPittyPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalPittyPoints) as TAmigo} />
        </Col>
        
        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Pitty Point Events"
            metric={latestEvent?.totalPittyPointEvents}
            value={`${highestVal(latestEvent?.totalPittyPointEvents)} events`}
            amigo={keyWithHighestVal(latestEvent?.totalPittyPointEvents) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Catan Points"
            metric={latestEvent?.totalCatanPoints}
            value={`${highestVal(latestEvent?.totalCatanPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalCatanPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most All-Time Consecutive Events Spent in 1st"
            metric={latestEvent?.longestFirstPlaceStreak}
            value={`${highestVal(latestEvent?.longestFirstPlaceStreak)} events`}
            amigo={keyWithHighestVal(latestEvent?.longestFirstPlaceStreak) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Current Consecutive Events Spent in 1st"
            metric={latestEvent?.currentFirstPlaceStreak}
            value={`${highestVal(latestEvent?.currentFirstPlaceStreak)} events`}
            amigo={keyWithHighestVal(latestEvent?.currentFirstPlaceStreak) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Bball Points"
            metric={latestEvent?.totalBballPoints}
            value={`${highestVal(latestEvent?.totalBballPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalBballPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Disc Golf Points"
            metric={latestEvent?.totalDiscGolfPoints}
            value={`${highestVal(latestEvent?.totalDiscGolfPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalDiscGolfPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Tennis Points"
            metric={latestEvent?.totalTennisPoints}
            value={`${highestVal(latestEvent?.totalTennisPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalTennisPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Highest Single Point Event"
            // metric={latestEvent?.largestSingleEventPointsEarned}
            value={`${latestEvent?.largestSingleEventPointsEarned} points`}
            amigo={latestEvent?.largestSingleEventPointsAmigo as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Largest 1st Place Lead"
            metric={latestEvent?.largestPointLead}
            value={`${highestVal(latestEvent?.largestPointLead)} points`}
            amigo={keyWithHighestVal(latestEvent?.largestPointLead) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">
          <StatBlock 
            title="Most Romazarc Points"
            metric={latestEvent?.totalRomazarcPoints}
            value={`${highestVal(latestEvent?.totalRomazarcPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalRomazarcPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Most Points Deducted"
            metric={latestEvent?.totalPointsLost}
            value={`${highestVal(latestEvent?.totalPointsLost)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalPointsLost) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Most Super Smash Points"
            metric={latestEvent?.totalSSBPoints}
            value={`${highestVal(latestEvent?.totalSSBPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalSSBPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Most Points from All Sports"
            metric={latestEvent?.totalSportsPoints}
            value={`${highestVal(latestEvent?.totalSportsPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalSportsPoints) as TAmigo} />
        </Col>

        {/* <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="2nd Place in  Points from All Sports"
            value={`${highestVal(latestEvent?.totalSportsPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalSportsPoints) as TAmigo} />
        </Col> */}

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Most Points from All Board Games"
            metric={latestEvent?.totalBoardGamePoints}
            value={`${highestVal(latestEvent?.totalBoardGamePoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalBoardGamePoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Most Points from Billiards"
            metric={latestEvent?.totalBilliardsPoints}
            value={`${highestVal(latestEvent?.totalBilliardsPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalBilliardsPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Best Finder Eye"
            metric={latestEvent?.totalFinderPoints}
            value={`${highestVal(latestEvent?.totalFinderPoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalFinderPoints) as TAmigo} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Shortest Amigo"
            value={'5\' 6.125"'}
            amigo={'a'} />
        </Col>

        <Col sm={6} md={4} xl={3} xxl={2} className="colored-stat-block">  
          <StatBlock 
            title="Most Points from All Video Games"
            metric={latestEvent?.totalVideoGamePoints}
            value={`${highestVal(latestEvent?.totalVideoGamePoints)} points`}
            amigo={keyWithHighestVal(latestEvent?.totalVideoGamePoints) as TAmigo} />
        </Col>
    </Row >
    </>
  )
}
