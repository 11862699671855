import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import {
} from '../../internal';

interface IProps {

}

export const Projects: FC<IProps> = ({ }) => {
  return (
    <Row justify="around" className={classnames('page')}>
      <Col sm={10}>
        <Row>
          <Col xs={12}>
            <h4>lion oil music</h4>
          </Col>
          <Col xs={12}>
            <h4><a href="https://soundcloud.com/lion_oil" target="_blank">
              Listen on Soundcloud
            </a></h4>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h4>Banned Words Online</h4>
          </Col>
          <Col xs={12}>
            <h4><a href="https://bannedwords.fun" target="_blank">
              Play the game at bannedwords.fun
            </a></h4>
          </Col>
        </Row>
      </Col>

      
    </Row >
  )
}
