/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCheer = /* GraphQL */ `
  subscription OnCheer($for: String!) {
    onCheer(for: $for) {
      id
      for
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCheer = /* GraphQL */ `
  subscription OnCreateCheer {
    onCreateCheer {
      id
      for
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCheer = /* GraphQL */ `
  subscription OnUpdateCheer {
    onUpdateCheer {
      id
      for
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCheer = /* GraphQL */ `
  subscription OnDeleteCheer {
    onDeleteCheer {
      id
      for
      createdAt
      updatedAt
    }
  }
`;
