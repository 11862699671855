import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'

import App from './App'
import awsExports from './aws-exports'
import './styles/main.scss'


// Initialize Amplify
Amplify.configure(awsExports)

ReactDOM.render(<App />, document.getElementById('root'))

