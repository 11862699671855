import React, { FC, useState, useEffect, useContext, useRef } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden, ScreenClassRender} from 'react-grid-system';

import {
  AmigoCard,
  IEvent,
  graphQLSubscription,
  graphQLCount,
  countCheers,
  onCheer,

} from '../../internal';

const eventData = require('../../parsedEvents.json');

interface IProps {

}

export const Landing: FC<IProps> = ({ }) => {
  var [latestEvent, setLatestEvent] = useState<IEvent>();
  var [recent1StandingsChangeAgo, setRecent1StandingsChangeAgo] = useState<IEvent>();
  var [recent1StandingsChangeAgo, setRecent1StandingsChangeAgo] = useState<IEvent>();
  var [recent2StandingsChangeAgo, setRecent2StandingsChangeAgo] = useState<IEvent>();
  var [recent3StandingsChangeAgo, setRecent3StandingsChangeAgo] = useState<IEvent>();
  var [recent4StandingsChangeAgo, setRecent4StandingsChangeAgo] = useState<IEvent>();
  var [recent5StandingsChangeAgo, setRecent5StandingsChangeAgo] = useState<IEvent>();
  var [recent6StandingsChangeAgo, setRecent6StandingsChangeAgo] = useState<IEvent>();
  var [recent7StandingsChangeAgo, setRecent7StandingsChangeAgo] = useState<IEvent>();

  var [ scottCheers, setScottCheers] = useState<number>(0);
  var [ alexCheers, setAlexCheers ] = useState<number>(0);
  var [ mjCheers, setMjCheers ] = useState<number>(0);

  var [ scottCheered, setScottCheered ] = useState<boolean>(false);
  var [ alexCheered, setAlexCheered ] = useState<boolean>(false);
  var [ mjCheered, setMjCheered ] = useState<boolean>(false);

  var swgCheerRef = useRef(0);
  var mjCheerRef = useRef(0);
  var alCheerRef = useRef(0);

  useEffect(() => {
    const recentNow = eventData[eventData.length - 1];
    setLatestEvent(recentNow);

    // get 5 most recent standings changes
    const recentStandingsChanges: IEvent[] = [];
    eventData.some((ev: IEvent, index: number) => {
      const mostRecentRecentChange = recentStandingsChanges[recentStandingsChanges.length - 1];
      // the most recent recent change or start from the most recent event if none found yet
      const a = mostRecentRecentChange ?? eventData[eventData.length - (index+1)];
      const b = eventData[eventData.length - (index+2)];
      console.log(`comparing ev: ${a.name} to ${b.name}`);
      console.log(`a: ${JSON.stringify(a.runningPositions)}`);
      console.log(`b: ${JSON.stringify(b.runningPositions)}`);
      if (a.runningPositions.a === b.runningPositions.a 
        && a.runningPositions.s === b.runningPositions.s 
        && a.runningPositions.m === b.runningPositions.m) {
        console.log('---- no standings changes');
      } else {
        recentStandingsChanges.push(b);
        console.log(`---- found ${recentStandingsChanges.length}th change in standings!`);
        
      }
      
      return recentStandingsChanges.length >= 5;
    });

    const recent1Ago = recentStandingsChanges[0];
    setRecent1StandingsChangeAgo(recent1Ago);

    const recent2Ago = recentStandingsChanges[1];
    setRecent2StandingsChangeAgo(recent2Ago);

    const recent3Ago = recentStandingsChanges[2];
    setRecent3StandingsChangeAgo(recent3Ago);

    const recent4Ago = recentStandingsChanges[3];
    setRecent4StandingsChangeAgo(recent4Ago);

    const recent5Ago = recentStandingsChanges[4];
    setRecent5StandingsChangeAgo(recent5Ago);

    // const recent6Ago = recentStandingsChanges[5];
    // setRecent6StandingsChangeAgo(recent6Ago);

    // const recent7Ago = recentStandingsChanges[6];
    // setRecent7StandingsChangeAgo(recent7Ago);

    (async () => {
      const foundACheers: number = await graphQLCount(countCheers, 'searchCheers', {
        for: 'a'
      });

      const foundMCheers: number = await graphQLCount(countCheers, 'searchCheers', {
        for: 'm'
      });

      const foundSCheers: number = await graphQLCount(countCheers, 'searchCheers', {
        for: 's'
      });

      swgCheerRef.current = foundSCheers;
      alCheerRef.current = foundACheers;
      mjCheerRef.current = foundMCheers;
      setAlexCheers(foundACheers);
      setScottCheers(foundSCheers);
      setMjCheers(foundMCheers);

    })();

    // subscribe to cheers
    var alCheerSubscription = graphQLSubscription(onCheer, { for: 'a' }, cheered);
    var mjCheerSubscription = graphQLSubscription(onCheer, { for: 'm' }, cheered);
    var swgCheerSubscription = graphQLSubscription(onCheer, { for: 's' }, cheered);
    
    return () => {
      alCheerSubscription.unsubscribe();
      mjCheerSubscription.unsubscribe();
      swgCheerSubscription.unsubscribe();
    }
  }, []);


  useEffect(() => {
    setAlexCheers(alCheerRef.current);
    setScottCheers(swgCheerRef.current);
    setMjCheers(mjCheerRef.current);
  }, [alCheerRef.current, mjCheerRef.current, swgCheerRef.current])

  const cheered = async ({ onCheer: cheer }) => {
    switch(cheer.for) {
      case 's': 
        setScottCheers(swgCheerRef.current + 1);
        swgCheerRef.current++;// = swgCheerRef.current + 1;
        setScottCheered(true);
        break;
      case 'a': 
        setAlexCheers(alCheerRef.current + 1);
        alCheerRef.current++;// = alCheerRef.current + 1;
        setAlexCheered(true);
        break;
      case 'm': 
        setMjCheers(mjCheerRef.current + 1);
        mjCheerRef.current++;// = mjCheerRef.current + 1;
        setMjCheered(true);
        break;
      }
  }

  return (
    <Container>
      <ScreenClassRender render={(screenClass: string) => {
        var noPaddingOnXs = {};
        if (screenClass === 'xs' || screenClass === 'sm') {
          noPaddingOnXs = {
            paddingLeft: '0px',
            paddingRight: '0px',
          };
        }

        return (
          <Row direction="column" justify="center" nogutter>
            <Col xs={12}>
              <h2 className="centered">Ultimate Roommate Challenge Standings</h2>
            </Col>

            <Row justify="around">
              <Col xs={4} style={{ alignSelf: 'flex-end', ...noPaddingOnXs }}> 
                <AmigoCard
                  cheerCount={mjCheers}
                  cheered={mjCheered}
                  amigo="m"
                  event={latestEvent} 
                  screenClass={screenClass} />

                <div className={classnames('cheer-count', screenClass, { 'invisible':  mjCheers === 0 })}>{mjCheers} cheers</div>
              </Col>

              <Col xs={4} style={{ alignSelf: 'flex-end', ...noPaddingOnXs }}>
                <AmigoCard
                  cheerCount={scottCheers}
                  cheered={scottCheered}
                  amigo="s"
                  event={latestEvent} 
                  screenClass={screenClass} />
                <div className={classnames('cheer-count', screenClass, { 'invisible':  scottCheers === 0 })}>{scottCheers} cheers</div>
              </Col>

              <Col xs={4} style={{ alignSelf: 'flex-end', ...noPaddingOnXs }}>
                <AmigoCard
                  cheerCount={alexCheers}
                  cheered={alexCheered}
                  amigo="a"
                  event={latestEvent} 
                  screenClass={screenClass} />
                <div className={classnames('cheer-count', screenClass, { 'invisible':  alexCheers === 0 })}>{alexCheers} cheers</div>
              </Col>

            </Row>

            <Row justify="around">
              <Col xs={12}>
                <h2 className="centered recent-standings">Recent Standings</h2>
              </Col>
              <Col xs={12}>
                {/* 1st recent standings change */}
                <Row>
                  <Col xs={12}>
                    <h5 className="centered recent-standings-date">{recent1StandingsChangeAgo?.date}</h5>
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="m"
                      short={true}
                      event={recent1StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="s"
                      short={true}
                      event={recent1StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="a"
                      short={true}
                      event={recent1StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                </Row>

                {/* 2nd recent standings change */}
                <Row>
                  <Col xs={12}>
                    <h5 className="centered recent-standings-date">{recent2StandingsChangeAgo?.date}</h5>
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="m"
                      short={true}
                      event={recent2StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="s"
                      short={true}
                      event={recent2StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="a"
                      short={true}
                      event={recent2StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                </Row>

                {/* 3rd recent standings change */}
                <Row>
                  <Col xs={12}>
                    <h5 className="centered recent-standings-date">{recent3StandingsChangeAgo?.date}</h5>
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="m"
                      short={true}
                      event={recent3StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="s"
                      short={true}
                      event={recent3StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="a"
                      short={true}
                      event={recent3StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                </Row>

                {/* 4th recent standings change */}
                <Row>
                  <Col xs={12}>
                    <h5 className="centered recent-standings-date">{recent4StandingsChangeAgo?.date}</h5>
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="m"
                      short={true}
                      event={recent4StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="s"
                      short={true}
                      event={recent4StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="a"
                      short={true}
                      event={recent4StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                </Row>

                {/* 5th recent standings change */}
                <Row>
                  <Col xs={12}>
                    <h5 className="centered recent-standings-date">{recent5StandingsChangeAgo?.date}</h5>
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="m"
                      short={true}
                      event={recent5StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="s"
                      short={true}
                      event={recent5StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                  <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                    <AmigoCard
                      amigo="a"
                      short={true}
                      event={recent5StandingsChangeAgo} 
                      screenClass={screenClass} />
                  </Col>
                </Row>
              </Col>

              {/* 6th recent standings change */}
              {/* <Row>
                <Col xs={12}>
                  <h5 className="centered recent-standings-date">{recent6StandingsChangeAgo?.date}</h5>
                </Col>
                <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                  <AmigoCard
                    amigo="m"
                    short={true}
                    event={recent6StandingsChangeAgo} 
                    screenClass={screenClass} />
                </Col>
                <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                  <AmigoCard
                    amigo="s"
                    short={true}
                    event={recent6StandingsChangeAgo} 
                    screenClass={screenClass} />
                </Col>
                <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                  <AmigoCard
                    amigo="a"
                    short={true}
                    event={recent6StandingsChangeAgo} 
                    screenClass={screenClass} />
                </Col>
              </Row> */}

              {/* 7th recent standings change */}
              {/* <Row>
                <Col xs={12}>
                  <h5 className="centered recent-standings-date">{recent7StandingsChangeAgo?.date}</h5>
                </Col>
                <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                  <AmigoCard
                    amigo="m"
                    short={true}
                    event={recent7StandingsChangeAgo} 
                    screenClass={screenClass} />
                </Col>
                <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                  <AmigoCard
                    amigo="s"
                    short={true}
                    event={recent7StandingsChangeAgo} 
                    screenClass={screenClass} />
                </Col>
                <Col xs={4} style={{alignSelf: 'flex-end', paddingLeft: '2px', paddingRight: '2px' }}> 
                  <AmigoCard
                    amigo="a"
                    short={true}
                    event={recent7StandingsChangeAgo} 
                    screenClass={screenClass} />
                </Col>
              </Row> */}

            </Row>
          </Row >
        )
      }} />
    </Container>
  )
}
