import React, { FC, useEffect } from 'react';
import classnames from 'classnames'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { useParams, Link, NavLink } from "react-router-dom";
import { getRandomNumBetween } from '../internal'

interface IProps {

}

export const Header: FC<IProps> = () => {
  
  return  (
    <div className={classnames('header')}>
      <ScreenClassRender render={(screenClass: string) => (
        <Row justify="between" nogutter>  

          <Col xs={12} sm={4} className={classnames('title-head', screenClass)}>
            <h4 style={{ marginBottom: '0px' }}>¡Los Tres Amigos!</h4>
          </Col>
            
          <Col xs={12} sm={8} className={classnames('links-container', screenClass)}>
            <div className="header-link">
              <NavLink to="/standings" activeClassName="current">
                Standings
              </NavLink>
            </div>

            <div className="header-link">
              <NavLink to="/events" activeClassName="current">
                Events
              </NavLink>
            </div>

            <div className="header-link">
              <NavLink to="/stacks" activeClassName="current">
                Stacks
              </NavLink>
            </div>

            <div className="header-link">
              <NavLink to="/stats" activeClassName="current">
                Statistics
              </NavLink>
            </div>
            
            {/* 
            <div className="header-link">
              <NavLink to="/pages/projects" activeClassName="current">
                Projects
              </NavLink>
            </div> 
            */}
            
          </Col>


        </Row>
      )} />
    </div>
  );
}