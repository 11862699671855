import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { useParams, Link } from "react-router-dom";
import {
  ConditionalRender,
  TAmigo,
  AmigoHead,
  IAmigoVals
} from '../internal';

interface IProps {
  title: string,
  value: string,
  amigo?: TAmigo,
  numeric?: boolean,
  subTitle?: string,
  metric?: IAmigoVals,
}

export const StatBlock: FC<IProps> = ({ title, subTitle, value, amigo, metric }) => {

  return (
    <div className="stat-block">
      <div className="stat-title">
        <h3 className="centered">{title}</h3>
      </div>

      <ConditionalRender visible={!!subTitle}>
        <div className="stat-sub-title">
          <h4 className="centered">{subTitle}</h4>
        </div>
      </ConditionalRender>

      {/* lead amigo */}
      <ConditionalRender visible={!!value}>
        <div className={classnames('stat-value', { 'numeric': !amigo })}>
          <h4 className="centered">{value}</h4>
        </div>
      </ConditionalRender>

      <ConditionalRender visible={!!amigo}>
        <AmigoHead
          amigo={amigo!} />
      </ConditionalRender>
      
      <ConditionalRender visible={!!metric}>
        <div className="non-leading-heads">
          <ConditionalRender visible={amigo !== 'm'}>
            <div className="non-leader-text">
              MJ({metric?.m}) 
              </div>
          </ConditionalRender>
          <ConditionalRender visible={amigo !== 's'}>
            <div className="non-leader-text">
              SWG({metric?.s})  
            </div>
          </ConditionalRender>
          
          <ConditionalRender visible={amigo !== 'a'}>
            <div className="non-leader-text">
              AL({metric?.a}) 
            </div>
          </ConditionalRender>
        </div>
      </ConditionalRender>
    </div>  
  );

}