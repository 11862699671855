// custom hooks
import { SfxManager } from './audio';

import { TAmigo } from '../internal'

export * from './audio';
export * from './useMap';
export * from './useSet';
export * from './colorTheme';

export const sfx = new SfxManager();
// set the volume lower
sfx.volume(SfxManager.defaultVolume);

export const getRandomNumBetween = (min: number, max: number) => {
  // console.log(`getting rand num between: ${min} - ${max}`);
  return Math.floor(Math.random() * (max - min)) + min;
}

export const getAmigoName = (amigo: TAmigo, short: boolean = false): string => {
    switch(amigo) {
      case 's': 
        return short ? 'SWG' : 'Scott';
      case 'a': 
        return short ? 'AL' : 'Alex';
      case 'm': 
        return short ? 'MJ' : 'Michael';
      default: 
        return 'This Guy';
    }
}


export const getAmigoAbbreviation = (amigoFullName: string): string => {
  return amigoFullName?.[0]?.toLowerCase();
}