import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { FaTrophy } from 'react-icons/fa';
import { BsTrophyFill } from 'react-icons/bs'
import { GiTrophy } from 'react-icons/gi'

import {
  ConditionalRender,
  getRandomNumBetween,
  TAmigo,
  IAmigoVals,
  IEvent,
  getAmigoName
} from '../internal'
import { ScreenClassRender } from 'react-grid-system';


export interface ITrophyProps {
    amigo: TAmigo,
    standings?: IAmigoVals
}

export const Trophy: FC<ITrophyProps> = ({ amigo, standings }) => {

  return  (
    <ScreenClassRender render={(screenClass: string) => (
      <div className={classnames('trophy-container', screenClass)}>
        {/* 1st */}
        <ConditionalRender visible={standings?.[amigo] === 0}>
          <div className="trophy first">
            <GiTrophy />
          </div>
        </ConditionalRender>
        {/* 2nd */}
        <ConditionalRender visible={standings?.[amigo] === 1}>
          <div className="trophy second">
            <BsTrophyFill />
          </div>
        </ConditionalRender>
        {/* 3rd */}
        <ConditionalRender visible={standings?.[amigo] === 2}>
          <div className="trophy third">
            <FaTrophy />
          </div>
        </ConditionalRender>
      </div>
    )} />
  );
}