// declare module '*.mp3'
import { Howl, Howler } from 'howler';

// import sound_ticking from '../assets/audio/transition/ticking.mp3';


export enum ESoundName {
  // ticking = 'ticking',
}

export interface ICustomSfxOptions {
  fadeDuration?: number,
  fadeFrom?: number,
  fadeTo?: number,
  volume?: number

}

export class SfxManager {

  static defaultVolume = 0.1;

  // ticking: Howl[];

  constructor() {
    //get volume previously stored in the cache
    const prevSetVol = sessionStorage.getItem('volume');
    if (prevSetVol) {
      const volVal = Number(prevSetVol);
      Howler.volume(volVal);
    } else {
      // default volume = 30%;
      Howler.volume(SfxManager.defaultVolume);
    }


    // this.ticking = [
    //   this.setupClip(sound_ticking, {
    //     fadeDuration: 8000,
    //     fadeFrom: 0,
    //     fadeTo: .6
    //   })
    // ]

  }

  volume(vol: number) {
    // Change global volume.
    // 0.0 - 1.0
    Howler.volume(vol);

    sessionStorage.setItem('volume', `${vol}`);
  }

  stopAll() {
    Howler.stop();
  }

  stopSound(soundId: string) {
    console.log(`stoppingSound w id: ${soundId}`);
    Howler.stop(soundId);
  }

  play(sfxName: ESoundName, selectorVal: number = 0, stopTimeMs?: number) {
    const sounds = this[sfxName];
    if (Array.isArray(sounds)) {
      var clip = sounds[selectorVal % sounds.length];

      // console.log(`state of ${sfxName} is ${clip.state()}`);
      // play the clip
      var playId = clip?.customPlay?.(clip) ?? clip?.play?.();

      // if we have a stop time in mind, stop it then
      var timeoutRef;
      if (Number.isInteger(stopTimeMs)) {
        timeoutRef = setTimeout(() => {
          this.stopSound(playId);
        }, stopTimeMs);
      }

      return {
        ref: timeoutRef,
        stopMe: () => {
          clearTimeout(timeoutRef);
          this.stopSound(playId);
        },
        playId,
      };
    }
  }

  private setupClip(clipSrc: string, opts: ICustomSfxOptions = {}) {
    const clip = new Howl({
      src: [clipSrc],
      ...opts
    });

    clip.on('load', function () {
      console.log('howler - Loaded sound!');
    });

    clip.on('loaderror', function (err, err1) {
      console.log('howler - error loading sound!');
    });

    // add a custom play method with fade 
    if (!!opts.fadeDuration) {
      clip.customPlay = () => {
        const playId = clip.play();
        // fade clip based on options
        clip.fade(
          opts.fadeFrom ?? 0,
          opts.fadeTo ?? 1,
          opts.fadeDuration,
          playId);
        return playId;
      };
    }
    return clip;
  }


}
