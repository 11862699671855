import React, { FC, useState, useEffect, useContext } from 'react'
import classnames from 'classnames'
import c3 from 'c3';
import { Container, Row, Col, Hidden } from 'react-grid-system';


interface IProps {
  chartId: string,
  data: any,
  tooltip: any,
  axis: any
}

export const Chart: FC<IProps>  = ({ chartId, data, tooltip, axis }) => {
  
  useEffect(() => {
    c3.generate({
      bindto: `#${chartId}`,
      data,
      tooltip,
      axis
    });
  }, [data, chartId]);
  
  return (
    <div id={`${chartId}`} />
  );
};