import { useState, useEffect } from 'react';

export const useMap = (initialMap: any) => {
  var [myMap, setMyMap] = useState<any>(initialMap);
  var [mapUpdator, setMapUpdator] = useState(0);

  // useEffect(() => {
  //   function handleStatusChange(status) {
  //     setIsOnline(status.isOnline);
  //   }

  // });

  const customSetMyMap = (m: any) => {
    setMapUpdator(Date.now());
    setMyMap(m);
  }

  return [myMap, customSetMyMap, mapUpdator];
}